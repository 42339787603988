




















































































































































































































































































































































































































































































.alert-sm {
	@apply bg-red-100 flex-row items-center px-3 rounded-full {}
	@apply text-red-500 text-xs flex py-2 {}
	&-icon {
		@apply bg-red-500 flex flex-shrink-0 h-20px items-center justify-center mr-2 {}
		@apply rounded-full text-center text-white text-xs w-20px {} } }
